<template>
  <section id="header">
    <nav-bar />
    <div class="shapes left"></div>
    <div class="container call-to-action">
      <h2 class="headline mobile">
        Real life <span>NFT</span>
        <span>experiences</span>
      </h2>
      <h2 class="headline tablet">Real life <span>NFT experiences</span></h2>
      <p>
        Create a one of a kind physical NFT based experiences for your customers
        at scale. Develop communities and deep loyalty in a whole new way.
      </p>
      <div id="call-to-action-btns" class="container">
        <div class="row">
          <div
            class="
              header-btns
              d-flex
              justify-content-between
              align-items-center
              p-0
            "
          >
            <b-button @click="$root.$emit('scrollTo', 'why-mint')">Learn more</b-button>
            <b-button @click="$root.$emit('scrollTo', 'contact-us')">Contact Us</b-button>
          </div>
        </div>
      </div>
      <div class="works-with container">
        <div class="row">
          <h5>Works With</h5>
          <img
            src="../../public/svg/supported.svg"
            alt="Supported Services"
          />
        </div>
      </div>
    </div>
    <div class="shapes right"></div>
  </section>
</template>

<script>
import NavBar from "./NavBar.vue";
export default {
  components: {
    NavBar,
  },
};
</script>

<style scoped lang="scss" src="../assets/styles/Header.scss"></style>