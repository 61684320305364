<template>
  <section id="our-kiosk" class="overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-xl-6 features-col">
          <h3>Our Kiosk</h3>
          <p>
            Our IRL Kiosk is specifically designed with NFTs in mind. Fully Web3
            enabled and smart, they are the ultimate tool in distributing NFTs
            wherever you install them.. We’ve designed them to be easy to use
            and powerful enough to handle all your creative applications
          </p>
          <div class="features">
            <h4>IRL Minting KIOSK includes:</h4>
            <ul>
              <li>
                <p>21.5 inch touch screen</p>
              </li>
              <li>
                <p>QR code / Barcode scanner</p>
              </li>
              <li>
                <p>Cameras</p>
              </li>
              <li>
                <p>Bluetooth NFC coms</p>
              </li>
              <li>
                <p>Card Dip for payments</p>
              </li>
              <li>
                <p>Receipt Printer</p>
              </li>
              <li>
                <p>Customizable Brand interface</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-xl-6 media-col">
          <img
            class="mobile w-100"
            src="../../public/png/our-kiosk-mobile.png"
            alt="Kiosk Touchscreen Teaser"
          />
          <img
            class="tablet w-100"
            src="../../public/png/our-kiosk-tablet.png"
            alt="Kiosk Touchscreen Teaser"
          />
          <img
            class="desktop w-100"
            src="../../public/png/our-kiosk-desktop.png"
            alt="Kiosk Touchscreen Teaser"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss" src="../assets/styles/OurKiosk.scss"></style>