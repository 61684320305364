<template>
  <section id="hero-banner">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>
            IRLMint is the <span>physical gateway</span> into the Metaverse &
            Web3
          </h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss" src="../assets/styles/HeroBanner.scss"></style>