<template>
  <section id="platform" class="overflow-hidden">
    <div class="container px-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-12">
              <h3>The platform</h3>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-sm-12 col-xl-6">
              <div class="features">
                <h4>Simple Administrative <br />Dashboard Management</h4>
                <ul>
                  <li><p>Zero Code UI Design</p></li>
                  <li><p>Multi-blockchain minting</p></li>
                  <li><p>NFT campaign manager</p></li>
                  <li><p>All-in-one NFT membership toolbox</p></li>
                  <li><p>Remote monitoring and kiosk fleet management</p></li>
                </ul>
              </div>
            </div>
            <div class="col-sm-12 col-xl-6">
              <img
                class="desktop"
                src="../../public/png/dashboard-mock-desktop.png"
                alt="Administrative Dashboard Mock"
              />
              <img
                class="tablet"
                src="../../public/png/dashboard-mock-tablet.png"
                alt="Administrative Dashboard Mock"
              />
              <img
                class="mobile"
                src="../../public/png/dashboard-mock-desktop.png"
                alt="Administrative Dashboard Mock"                
              />
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12 col-xl-6">
              <img
                class="mobile"
                src="../../public/png/customer-service-options-mobile.png"
                alt="Customer Service Mock"
              />
              <img
                class="tablet"
                src="../../public/png/customer-service-options-tablet.png"
                alt="Customer Service Mock"
              />
              <img
                class="desktop"
                src="../../public/png/customer-service-options-desktop.png"
                alt="Customer Service Mock"
              />
            </div>
            <div class="col-sm-12 col-xl-6 order-first">
              <div class="features">
                <h4>Easy and Intuitive <br />Minting for your Users</h4>
                <ul>
                  <li><p>Informative educational content</p></li>
                  <li><p>White-label Customer Service Support</p></li>
                </ul>
              </div>
              <div class="features">
                <h4>Sleek Kiosk Design</h4>
                <ul>
                  <li><p>At factory branding options</p></li>
                </ul>
              </div>
              <div class="features">
                <h4>Customer Service Options</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss" src="../assets/styles/Platform.scss">
</style>