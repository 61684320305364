<template>
  <section id="experience" class="overflow-hidden">
    <div class="container-fluid px-0">
      <div class="row no-gutters">
        <div class="col-sm-12 col-xl-8 headline-col">
          <h3>Custom Tailored</h3>
          <p>
            By leveraging our collective NFT and Kiosk knowledge we craft highly
            customized experiences for major brands.
          </p>
        </div>
        <div class="col-sm-12 col-xl-4 details-col">
          <div class="row no-gutters">
            <div class="col-sm-12 col-md-6 col-xl-12">
              <div class="detail">
                <h4>
                  <img class="mb-1" style="margin-right: 5px" src="../../public/svg/bx-help-circle.svg" />
                  What you get
                </h4>
                <p>
                  We enable you with a device and connected minting platform that
                  allow you to create your own NFT based experiences.
                </p>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-xl-12">
              <div class="detail">
                <h4>
                  <img class="mb-1" style="margin-right: 5px" src="../../public/svg/bx-help-circle.svg" />
                  How we do it
                </h4>
                <p>
                  We work closely with you in the initial implementation teaching
                  you all the secrets to building like a NFT Wizard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- hidden -->
      <div class="row no-gutters d-none">
        <div class="col-12">
          <img
            class="mobile"
            src="../../public/png/experience-media-mobile.png"
            alt="Experience Teaser"
          />
          <img
            class="tablet w-100"
            src="../../public/png/experience-media-tablet.png"
            alt="Experience Teaser"
          />
          <img
            class="desktop"
            src="../../public/png/experience-media-desktop.png"
            alt="Experience Teaser"
          />
        </div>
      </div>
      <div class="row no-gutters one-of-a-kind d-flex align-items-center">
        <div class="col-sm-12 col-xl-3 col-md-12">
          <h3>One of a kind IRL Minting experience</h3>
        </div>
        <div class="col-sm-12 col-xl-9 col-md-12">
          <p>
            We’re experts in Web2, Web3, Cryptocurrency, Kiosk infrastructure,
            and NFTs. This allows us to get to know your brand and apply
            specific highly tailored strategies that will produce a one of a
            kind IRL Minting experiences for your customers.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss" src="../assets/styles/Experience.scss"></style>