<template>
  <b-navbar id="navbar" type="light" toggleable="lg" variant="faded">
    <b-navbar-brand>
      <a href="/">
        <svg width="85" height="17" viewBox="0 0 85 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'d-block m-auto'" v-bind:width="'87'" v-bind:height="'24'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.584 17V.2h3.168V17H.584zm6.328 0V.2h7.296c1.056 0 1.976.184 2.76.552.784.368 1.392.888 1.824 1.56.432.672.648 1.464.648 2.376v.288c0 1.008-.24 1.824-.72 2.448a4.233 4.233 0 01-1.776 1.368v.432c.64.032 1.136.256 1.488.672.352.4.528.936.528 1.608V17h-3.168v-5.04c0-.384-.104-.696-.312-.936-.192-.24-.52-.36-.984-.36H10.08V17H6.912zm3.168-9.216h3.792c.752 0 1.336-.2 1.752-.6.432-.416.648-.96.648-1.632v-.24c0-.672-.208-1.208-.624-1.608-.416-.416-1.008-.624-1.776-.624H10.08v4.704zM22.076 17V.2h3.168v13.92h7.68V17H22.076z" fill="#131022"/><path d="M35.564 17V.2h3l5.088 15.888h.288L49.028.2h3V17H50.54V1.112h-.288L45.164 17h-2.736L37.34 1.112h-.288V17h-1.488zm20.65 0V5.336h1.44V17h-1.44zm.72-13.68c-.352 0-.648-.12-.888-.36s-.36-.536-.36-.888c0-.368.12-.664.36-.888.24-.24.536-.36.888-.36.368 0 .664.12.888.36.24.224.36.52.36.888 0 .352-.12.648-.36.888-.224.24-.52.36-.888.36zM61.885 17V5.336h1.392v2.16h.288c.288-.624.76-1.176 1.416-1.656.656-.496 1.608-.744 2.856-.744.848 0 1.624.176 2.328.528.704.352 1.264.888 1.68 1.608.432.704.648 1.592.648 2.664V17h-1.44V9.992c0-1.28-.32-2.2-.96-2.76-.64-.56-1.496-.84-2.568-.84-1.2 0-2.2.4-3 1.2-.8.784-1.2 1.976-1.2 3.576V17h-1.44zm18.592 0c-.624 0-1.096-.16-1.416-.48-.304-.32-.456-.768-.456-1.344V6.632H74.86V5.336h3.744V.968h1.44v4.368h4.08v1.296h-4.08v8.352c0 .48.24.72.72.72H83.5V17h-3.024z" fill="url(#paint0_linear_184_802)"/><defs><linearGradient id="paint0_linear_184_802" x1="-1" y1="9.057" x2="86" y2="9.057" gradientUnits="userSpaceOnUse"><stop stop-color="#6366F1"/><stop offset=".5" stop-color="#8B5CF6"/><stop offset="1" stop-color="#D946EF"/></linearGradient></defs></svg>
      </a>
    </b-navbar-brand>

    <b-navbar-toggle target="navbar-toggle-collapse border-none p-0">
      <template>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:id="'navbar-burger'" v-bind:width="'45'" v-bind:height="'45'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 14a2 2 0 012-2h28a2 2 0 110 4H6a2 2 0 01-2-2zm0 12a2 2 0 012-2h28a2 2 0 110 4H6a2 2 0 01-2-2z" fill="#131022"/></svg>
      </template>
    </b-navbar-toggle>

    <b-collapse
      id="navbar-toggle-collapse"
      class="navbar-collapse">
      <b-navbar-nav class="d-flex justify-content-center w-100">
        <b-nav-item>
          <div @click="emitScrollTo('why-mint')">Why Mint in real life?</div>
        </b-nav-item>

        <b-nav-item>
          <div @click="emitScrollTo('how-it-works')">How it works</div>
        </b-nav-item>

        <b-nav-item class="ml-auto">
          <div @click="emitScrollTo('the-experience')">The Experience</div>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav>
        <b-nav-item>
          <b-button
            id="contact-btn"
            style="min-width: 150px"
            @click="emitScrollTo('contact-us')">
            Contact Us
          </b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'nav-bar',
  methods: {
    emitScrollTo(loc) {
      this.$root.$emit('scrollTo', loc);
    }
  }
};
</script>

<style scoped>
#navbar {
  min-height: 88px;
  padding: 32px 20px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}
#contact-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  background: #6366F1;
  border-radius: 7px;
  padding: 18px 30px;
}
.nav-item div {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #131022;
  text-decoration: none;
}
button.navbar-toggler {
  border: none;
}
button.navbar-toggler:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
/* Dropdown Menu */
#navbar-toggle-collapse.show,
#navbar-toggle-collapse.collapsing {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 0.25rem 1rem;
}

@media screen and (min-width: 768px) {
  #navbar {
    padding: 32px 48px;
  }
}
</style>