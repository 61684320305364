<template>
  <section id="why-mint" class="overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <h3>Why Mint in real life?</h3>
        </div>
        <div class="col-sm-12 col-xl-6 details-col">
          <div class="reason">
            <h4>Increase event participation and engagement</h4>
            <p>
              NFTs are one of the best ways to build a community and keep
              that community engaged with your brand. With our custom NFT
              kiosk experience, you can drive value directly to your
              customer. Being able to dispense event based NFTs supercharges
              the relationship.
            </p>
          </div>
          <div class="reason">
            <h4>Distribute NFTs fairly</h4>
            <p>
              Because the user must be present, your NFTs have a real human
              interaction behind them. No more botting. Remove the noise between
              your brand and your community. Your collection receives a true 1
              to 1 distribution.
            </p>
          </div>
          <div class="reason">
            <h4>Reward your customers</h4>
            <p>
              By providing an IRL NFT minting experience through a kiosk format,
              your customers can easily collect your brand's NFTs in a
              personalized way. Once collected, you can continue to drop value
              and enhance the experience to the customer's wallet. Loyalty pays
              and NFTs nurture that loyalty.
            </p>
          </div>
          <img class="mobile why-mint-media" src="../../public/png/why-mint-mobile.png" alt="">
          <img class="tablet why-mint-media" src="../../public/png/why-mint-mock-tablet.png" alt="">
          <div class="reason d-xl-none">
            <h4>Learn even more about your customers</h4>
            <p>
              Our kiosks are intelligent and collect many demographic points
              from users including emotional and demographic intelligence
            </p>
          </div>
          <div class="reason d-xl-none">
            <h4>Gate access to exclusive events or areas</h4>
            <p>
              Embed special exclusive access into your NFTs and reward your
              customers by allowing them to join future events or receive
              special access. Use your IRL kiosks to verify ownership of NFTs
              and permit access to a special area or event.
            </p>
          </div>
          <!-- <img src="../../public/png/why-mint-mobile.png" alt="" class="mobile"> -->
        </div>
        <div class="col-sm-12 col-xl-6 px-0">
          <div class="wrap-image">
            <img
              class="mobile"
              src="../../public/png/why-mint-mock-mobile.png"
              alt="Mock"
              style="max-width: 350px"
            />
            <img
              class="tablet"
              src="../../public/png/why-mint-tablet.png"
              alt="Mock"
            />
            <img
              class="desktop"
              src="../../public/png/why-mint-tablet.png"
              alt="Mock"
            />
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row desktop-offset-details-row">
            <div class="col-6">
              <img
                src="../../public/png/why-mint-mock-tablet.png"
                alt="Dashboard Teaser"
              />
            </div>
            <div class="col-6">
              <div class="reason">
                <h4>Learn even more about your customers</h4>
                <p>
                  Our kiosks are intelligent and collect many demographic points
                  from users including emotional and demographic intelligence
                </p>
              </div>
              <div class="reason">
                <h4>Gate access to exclusive events or areas</h4>
                <p>
                  Embed special exclusive access into your NFTs and reward your
                  customers by allowing them to join future events or receive
                  special access. Use your IRL kiosks to verify ownership of NFTs
                  and permit access to a special area or event.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss" src="../assets/styles/WhyMint.scss"></style>