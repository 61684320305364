<template>
  <div id="app">
    <header-component ref="top" />
    <why-mint ref="why-mint" />
    <hero-banner />
    <platform ref="how-it-works" />
    <our-kiosk-section />
    <experience ref="the-experience" />
    <contact ref="contact-us" />
    <footer-component />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderSection.vue';
import WhyMint from './components/WhyMintSection.vue';
import HeroBanner from './components/HeroBannerSection.vue';
import Platform from './components/PlatformSection.vue';
import OurKioskSection from './components/OurKioskSection.vue';
import Experience from './components/ExperienceSection.vue';
import Contact from './components/ContactSection.vue';
import FooterComponent from './components/FooterNav.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    WhyMint,
    HeroBanner,
    Platform,
    OurKioskSection,
    Experience,
    Contact,
    FooterComponent
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName].$el;
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    registerRootEventListenerNavScroll() {
      this.$root.$on('scrollTo', (loc) => {
        this.goto(loc);
      });
    }
  },
  created () {
    this.registerRootEventListenerNavScroll()
  }
}
</script>

<style>
html, body, #app {
  height: 100%;
  width: 100%;
  font-family: 'Space Grotesk';
}

</style>
