<template>
  <section id="contact">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-xl-6">
          <div class="headline">
            <p>Ready to start minting?</p>
            <h3>Don’t hesitate to contact us</h3>
          </div>
        </div>
        <div class="col-sm-12 col-xl-6">
          <div id="contact-form">
            <b-form
              name="contact-us"
              @submit.prevent="submit"
              data-netlify="true"
              data-netlify-honeypot="true"
              method="post"
            >
              <input type="hidden" name="form-name" value="contact-us" />
              <b-row>
                <b-col xl="6">
                  <b-form-group
                    id="name-group"
                    label="Name"
                    label-for="name"
                    aria-describedby="name-invalid"
                  >
                    <b-form-input
                      id="name"
                      name="name"
                      v-model="form.name"
                      v-validate="{ required: true }"
                      type="text"
                      :state="validateState('name')"
                      aria-describedby="name-invalid"
                      data-vv-name="name"
                    ></b-form-input>
                    <b-form-invalid-feedback id="name-invalid">
                      {{ veeErrors.first("name") }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="email-group"
                    label="Email address"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      name="email"
                      v-model="form.email"
                      v-validate="{ required: true, email: true }"
                      type="email"
                      :state="validateState('email')"
                      aria-describedby="email-invalid"
                      data-vv-name="email"
                    ></b-form-input>
                    <b-form-invalid-feedback id="email-invalid">
                      {{ veeErrors.first("email") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col xl="6">
                  <b-form-group
                    id="company-group"
                    label="Company"
                    label-for="company"
                  >
                    <b-form-input
                      id="company"
                      name="company"
                      v-model="form.company"
                      v-validate="{ required: false, min: 3 }"
                      :state="validateState('company')"
                      aria-describedby="company-invalid"
                      type="text"
                      data-vv-name="company"
                    ></b-form-input>
                    <b-form-invalid-feedback id="company-invalid">
                      {{ veeErrors.first("company") }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="phone-group"
                    label="Phone"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      name="phone"
                      data-vv-name="phone"
                      v-model="form.phone"
                      v-validate="{
                        regex:
                          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                      }"
                      :state="validateState('phone')"
                      type="text"
                      aria-describedby="phone-invalid"
                    ></b-form-input>
                    <b-form-invalid-feedback id="phone-invalid">
                      {{ veeErrors.first("phone") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    id="message-group"
                    label="Interested in"
                    label-for="message"
                  >
                    <b-textarea
                      id="message"
                      name="message"
                      data-vv-name="message"
                      cols="50"
                      rows="5"
                      v-model="form.message"
                      v-validate="{ required: true }"
                      :state="validateState('message')"
                      aria-describedby="message-invalid"
                    ></b-textarea>
                    <b-form-invalid-feedback id="message-invalid">
                      {{ veeErrors.first("message") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button type="submit" variant="primary">
                Submit request
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container">
      <div class="row">
        <div class="col">
          <form netlify method="POST" class="form" data-netlify="true" data-netlify-honeypot="true">
            <input type="hidden" name="form-name" value="newsletter" />
            <fieldset>
              <label for="full-name">Name</label>
              <input type="text" class="form-control" name="full-name" />
            </fieldset>
            <fieldset>
              <label for="email">Email</label>
              <input type="email" class="form-control" name="email" />
            </fieldset>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        company: "",
        phone: "",
        message: "",
      },
      // form2: {
      //   fullName: "",
      //   email: ""
      // }
    };
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        company: "",
        phone: "",
        message: "",
      };
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        const axiosConfig = {
          header: { "Content-Type": "application/x-www-form-urlencoded" },
        };
        axios
          .post(
            "/",
            this.encode({
              "form-name": "contact-us",
              ...this.form,
            }),
            axiosConfig
          )
          .then(() => {
            alert('Thank you for your submission!');
            this.resetForm();
          });
      });
    },
  },
};
</script>

<style scoped lang="scss" src="../assets/styles/Contact.scss">
</style>