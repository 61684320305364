<template>
  <section id="footer">
    <footer class="container">
      <div class="row">
        <div class="col-sm-12">
          <svg width="140" height="28" viewBox="0 0 140 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:id="'footer-logo'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.64 28V0h5.28v28H.64zm10.547 0V0h12.16c1.76 0 3.293.307 4.6.92 1.306.613 2.32 1.48 3.04 2.6.72 1.12 1.08 2.44 1.08 3.96v.48c0 1.68-.4 3.04-1.2 4.08-.8 1.04-1.787 1.8-2.96 2.28v.72c1.067.053 1.893.427 2.48 1.12.587.667.88 1.56.88 2.68V28h-5.28v-8.4c0-.64-.173-1.16-.52-1.56-.32-.4-.867-.6-1.64-.6h-7.36V28h-5.28zm5.28-15.36h6.32c1.253 0 2.227-.333 2.92-1 .72-.693 1.08-1.6 1.08-2.72v-.4c0-1.12-.347-2.013-1.04-2.68-.694-.693-1.68-1.04-2.96-1.04h-6.32v7.84zM36.46 28V0h5.28v23.2h12.8V28H36.46zm22.48 0V0h5l8.48 26.48h.48L81.38 0h5v28H83.9V1.52h-.48L74.94 28h-4.56L61.9 1.52h-.48V28h-2.48zm34.416 0V8.56h2.4V28h-2.4zm1.2-22.8c-.587 0-1.08-.2-1.48-.6-.4-.4-.6-.893-.6-1.48 0-.613.2-1.107.6-1.48.4-.4.893-.6 1.48-.6.613 0 1.107.2 1.48.6.4.373.6.867.6 1.48 0 .587-.2 1.08-.6 1.48-.373.4-.867.6-1.48.6zm8.253 22.8V8.56h2.32v3.6h.48c.48-1.04 1.267-1.96 2.36-2.76 1.093-.827 2.68-1.24 4.76-1.24 1.413 0 2.707.293 3.88.88s2.107 1.48 2.8 2.68c.72 1.173 1.08 2.653 1.08 4.44V28h-2.4V16.32c0-2.133-.533-3.667-1.6-4.6-1.067-.933-2.493-1.4-4.28-1.4-2 0-3.667.667-5 2-1.333 1.307-2 3.293-2 5.96V28h-2.4zm30.985 0c-1.04 0-1.826-.267-2.36-.8-.506-.533-.76-1.28-.76-2.24V10.72h-6.24V8.56h6.24V1.28h2.4v7.28h6.8v2.16h-6.8v13.92c0 .8.4 1.2 1.2 1.2h4.56V28h-5.04z" fill="#fff"/></svg>
        </div>
        <div class="col-sm-12">
          <ul class="menu d-flex flex-wrap">
            <li>
              <div @click="emitScrollTo('top')">Home</div>
            </li>
            <li>
              <div @click="emitScrollTo('why-mint')">Why Mint in real life?</div>
            </li>
            <li>
              <div @click="emitScrollTo('how-it-works')">How it works</div>
            </li>
            <li>
              <div @click="emitScrollTo('the-experience')">The experience</div>
            </li>
            <li>
              <div @click="emitScrollTo('contact-us')">Contact Us</div>
            </li>
            <li>
              <div>Privacy Policy</div>
            </li>
            <li>
              <div>Terms & Conditions</div>
            </li>
          </ul>
        </div>
        <div class="col-sm-12">
          <ul class="socials d-flex">
            <li>
              <div class="icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.667.5H1.333a.833.833 0 00-.833.833v13.334c0 .46.373.833.833.833h7.18V9.7H6.563V7.43h1.948V5.762c0-1.938 1.184-2.994 2.917-2.994.583-.002 1.166.028 1.746.09v2.016h-1.196c-.94 0-1.123.448-1.123 1.104v1.446h2.247l-.291 2.27h-1.957V15.5h3.812c.46 0 .833-.373.833-.833V1.333A.833.833 0 0014.667.5z" fill="#fff"/></svg>
              </div>
            </li>
            <li>
              <div class="icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 6.147a3.852 3.852 0 10-.001 7.705 3.852 3.852 0 000-7.705zm0 6.356a2.503 2.503 0 11-.001-5.006 2.503 2.503 0 010 5.006zM14.005 6.904a.898.898 0 100-1.797.898.898 0 000 1.797z" fill="#fff"/><path d="M17.11 5.092A3.838 3.838 0 0014.918 2.9a5.505 5.505 0 00-1.822-.35c-.803-.035-1.057-.045-3.092-.045s-2.295 0-3.091.045a5.462 5.462 0 00-1.82.35 3.833 3.833 0 00-2.194 2.193 5.488 5.488 0 00-.35 1.822c-.035.802-.046 1.056-.046 3.092 0 2.035 0 2.294.046 3.091.013.624.13 1.239.35 1.823a3.841 3.841 0 002.195 2.193c.58.227 1.195.355 1.82.375.803.035 1.057.046 3.092.046s2.296 0 3.092-.046a5.514 5.514 0 001.821-.35 3.844 3.844 0 002.195-2.193c.219-.584.336-1.199.349-1.822.035-.802.046-1.056.046-3.092 0-2.035 0-2.294-.046-3.091a5.484 5.484 0 00-.351-1.848zm-1.014 7.944c-.006.48-.093.955-.26 1.406a2.49 2.49 0 01-1.426 1.426 4.153 4.153 0 01-1.392.26c-.791.036-1.015.045-3.045.045-2.031 0-2.239 0-3.045-.046a4.133 4.133 0 01-1.391-.259 2.487 2.487 0 01-1.433-1.426 4.234 4.234 0 01-.259-1.39c-.036-.792-.044-1.016-.044-3.046s0-2.238.044-3.045c.006-.48.093-.955.26-1.406a2.489 2.489 0 011.432-1.427 4.174 4.174 0 011.39-.26c.793-.035 1.016-.045 3.046-.045 2.031 0 2.24 0 3.045.046.476.006.946.093 1.392.26.655.252 1.172.77 1.427 1.426.163.445.251.916.259 1.39.036.793.045 1.016.045 3.046s0 2.249-.036 3.045h-.01z" fill="#fff"/></svg>
              </div>
            </li>
            <li>
              <div class="icon">
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.36 3.665c.012.145.012.29.012.435 0 4.438-3.378 9.551-9.55 9.551a9.485 9.485 0 01-5.155-1.507c.27.03.53.041.81.041 1.57 0 3.014-.53 4.168-1.434a3.364 3.364 0 01-3.14-2.327c.208.03.417.051.635.051.3 0 .603-.041.884-.114a3.356 3.356 0 01-2.691-3.294v-.042c.447.25.966.405 1.516.426a3.352 3.352 0 01-1.496-2.795c0-.623.165-1.195.456-1.693a9.547 9.547 0 006.922 3.512 3.815 3.815 0 01-.083-.769A3.355 3.355 0 0112.004.35c.967 0 1.84.405 2.453 1.06a6.63 6.63 0 002.13-.811 3.35 3.35 0 01-1.476 1.85 6.728 6.728 0 001.932-.52 7.205 7.205 0 01-1.682 1.736z" fill="#fff"/></svg>
              </div>
            </li>
            <li>
              <div class="icon">
                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.994 2.003A2.088 2.088 0 0015.526.53C14.22.173 9 .167 9 .167S3.78.161 2.474.504c-.7.19-1.278.767-1.471 1.481C.658 3.29.655 5.997.655 5.997s-.003 2.72.338 4.012a2.088 2.088 0 001.47 1.47c1.318.359 6.525.365 6.525.365s5.22.006 6.525-.336a2.096 2.096 0 001.473-1.47c.345-1.303.347-4.01.347-4.01s.017-2.72-.339-4.025zM7.33 8.505l.004-5 4.34 2.504L7.33 8.504z" fill="red"/></svg>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-sm-12">
          <p class="copyright">© IRL Mint 2022. All rights reserved.</p>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  methods: {
    emitScrollTo(loc) {
      this.$root.$emit('scrollTo', loc);
    }
  }
};
</script>

<style scoped lang="scss" src="../assets/styles/FooterNav.scss"></style>